import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "published", "unpublished" ]

  change() {
    let state = event.target.dataset.to
    let data = new FormData()
    data.append("published", state)

    Rails.ajax({
      url: this.data.get("url"),
      type: 'PATCH',
      data: data,
      success: this._update_view()
    })
  }

  _update_view() {
    this.publishedTarget.classList.toggle('hidden')
    this.unpublishedTarget.classList.toggle('hidden')
  }
}
