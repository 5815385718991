import { Controller } from "@hotwired/stimulus"
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["picker", "input"]

  initialize() {
    this.initPicker();
  }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.inputTarget.value,

      components: {
        preview: true,
        opacity: false,
        hue: true,

        interaction: {
          hex: true,
          rgba: true,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          cancel: true,
          clear: false,
          save: true,
        },
      },
    });

    this.picker.on('save', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString();

      this.picker.hide();
      Rails.fire(this.inputTarget.closest('form'), 'submit');
    });
  }
}
