import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "latitude", "longitude" ]

  connect(){
    import("leaflet").then( L => {
      var latTarget = this.latitudeTarget
      var lonTarget = this.longitudeTarget
      let coords = [latTarget.value, lonTarget.value]
      let zoom = this._zoom()
      let options = this._options()
      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      });

      this.map = L.map(this.containerTarget, {
        minZoom: 17,
        maxZoom: 20,
        zoomControl: false,
        maxBounds: [
          //south west
          this._maxBounds().sw,
          //north east
          this._maxBounds().ne
        ],
      }).setView(coords, zoom);

      L.tileLayer('https://api.mapbox.com/styles/v1/framegrabber/ckw0fy0za8roo14pljrdqjfrl/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}', {
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>, Styles © <a href="https://www.framegrabber-medien.com/">FRAMEGRABBER</a>',
          maxZoom: 20,
      //     id: 'mapbox/streets-v11',
      //     tileSize: 512,
      //     zoomOffset: -1,
          accessToken: 'pk.eyJ1IjoiZnJhbWVncmFiYmVyIiwiYSI6ImNrdzBmbjB4OWRhczMybnM3ZTV3N2I3NnMifQ.h0kT3DdBKoMP6NFLvAsVEw'
      }).addTo(this.map);

      L.control.zoom({
        position: 'bottomright'
      }).addTo(this.map);

      var markerLocation = new L.LatLng(coords[0], coords[1]);
      var marker = new L.Marker(markerLocation, {draggable: true});
      this.map.addLayer(marker);

      marker.on('dragend', function(){
        let position = marker.getLatLng();
        latTarget.value = position.lat
        lonTarget.value = position.lng
        if (options.includes("submitOnDragEnd")) {
          Rails.fire(latTarget.closest('form'), 'submit');
        }

      })

    });

  }

  disconnect(){
    this.map.remove()
  }
  _maxBounds(){
    return { sw: JSON.parse(this.data.get("boundssw")), ne: JSON.parse(this.data.get("boundsne"))}
  }
  _zoom(){
    return this.data.get("zoom")
  }
  _options(){
    return (this.data.get("options")) ? this.data.get("options").split(" ") : [];
  }

}
