// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application.scss"
import "@fortawesome/fontawesome-free/css/all.min"
import "leaflet/dist/leaflet.css"
import "@simonwep/pickr/dist/themes/nano.min.css"

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'


Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Rails = Rails

import "controllers"
