import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  connect(){
    import("leaflet").then( L => {
      this.map = L.map(this.containerTarget, {
        minZoom: 17,
        maxZoom: 20,
        zoomControl: false,
        maxBounds: [
          //south west
          this._maxBounds().sw,
          //north east
          this._maxBounds().ne
        ],
      }).setView(this._coordinates(), this._zoom());


      window.LocationsMap = this.map

      L.tileLayer('https://api.mapbox.com/styles/v1/framegrabber/ckw0fy0za8roo14pljrdqjfrl/tiles/256/{z}/{x}/{y}@2x?access_token={accessToken}', {
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>, Styles © <a href="https://www.framegrabber-medien.com/">FRAMEGRABBER</a>',
          maxZoom: 20,
      //     id: 'mapbox/streets-v11',
      //     tileSize: 512,
      //     zoomOffset: -1,
          accessToken: 'pk.eyJ1IjoiZnJhbWVncmFiYmVyIiwiYSI6ImNrdzBmbjB4OWRhczMybnM3ZTV3N2I3NnMifQ.h0kT3DdBKoMP6NFLvAsVEw'
      }).addTo(this.map);

      L.control.zoom({
        position: 'bottomright'
      }).addTo(this.map);

      var markers = this._markers()

      for (var i = 0; i < markers.length; i++) {
        var lat = markers[i][0];
        var lon = markers[i][1];
        var popupText = markers[i][2];
        var locationPath = markers[i][3];
        var markerColor = markers[i][4];
        var textColor = markers[i][5];

        this._addMarkerLayer(lat, lon, popupText, locationPath, markerColor, textColor)

      };
    });
  }

  _addMarkerLayer(lat, lon, popupText, locationPath, markerColor, textColor) {
    var markerLocation = new L.LatLng(lat, lon);
    var myIcon = L.divIcon({html: `<div style='background-color: ${markerColor}; color: ${textColor};' class='w-12 h-20 font-semibold p-1 flex justify-end'>${popupText}</div>`, iconSize:null});
    var marker = new L.Marker(markerLocation, {icon: myIcon});
    LocationsMap.addLayer(marker);

    marker.on('click', function(){
      window.location.replace(locationPath)
    });
  }

  disconnect(){
    this.map.remove()
  }

  _coordinates(){
    return [this.data.get("latitude"), this.data.get("longitude")]
  }
  _maxBounds(){
    return { sw: JSON.parse(this.data.get("boundssw")), ne: JSON.parse(this.data.get("boundsne"))}
  }
  _zoom(){
    return this.data.get("zoom")
  }
  _markers(){
    return JSON.parse(this.data.get("markers"))
  }

  addMarker(){
    var [data, status, xhr] = event.detail
    var response = new DOMParser().parseFromString(xhr.responseText, "text/xml");
    var marker = JSON.parse(response.firstChild.getAttribute('data-marker'))
    var lat = marker[0];
    var lon = marker[1];
    var popupText = marker[2];
    var locationPath = marker[3];
    var markerColor = marker[4];

    this._addMarkerLayer(lat, lon, popupText, locationPath, markerColor)
  }
}
