// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class extends Controller {
  static targets = [ "panel", "cards", "locales" ]

  connect() {
    let url = this.panelTarget.dataset.entryDefaultUrl
    let cardId = this.panelTarget.dataset.entryDefaultCardId
    this._load(url)
    this._paintCard(cardId)
    this.currentCard = cardId
  }

  show() {
    let card = event.target.closest('.card')
    let url = card.dataset.contentUrl
    this._paintCard(this.currentCard)
    this._load(url)
    this._paintCard(card.id)
    this.currentCard = card.id
  }


  _load(url) {
    this.panelTarget.querySelector('.content').innerHTML = ''
    this.panelTarget.querySelector('.loader').classList.remove('hidden')
    Rails.ajax({
      type: "GET",
      url: url,
      success: (_data, _status, xhr) => {
        this.panelTarget.querySelector('.loader').classList.add('hidden')
        this.panelTarget.querySelector('.content').innerHTML = xhr.response
      }
    })
  }

  _paintCard(id) {
    let cards = this.cardsTarget.querySelectorAll('.card')
    for (var i = 0; i < cards.length; i++) {
      cards[i].classList.add('bg-opacity-50')
    }
    this.cardsTarget.querySelector('#'+id).classList.toggle('bg-opacity-50')
  }

}
