import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "list" ]

  replace(event) {
    event.preventDefault()
    event.stopPropagation()

    const [data, status, xhr] = event.detail;
    this.containerTarget.innerHTML = xhr.response
  }

  append(event) {
    event.preventDefault()
    event.stopPropagation()

    const [, , xhr] = event.detail
    this.listTarget.insertAdjacentHTML('beforeend', xhr.response)
  }

  updateItem(event) {
    event.preventDefault()
    event.stopPropagation()

    const [, , xhr] = event.detail
    let id = event.target.dataset.updateItemId
    let el = document.getElementById(id)
    el.innerHTML = xhr.response
  }

  removeItem(event) {
    event.preventDefault()
    event.stopPropagation()

    const [, , xhr] = event.detail
    let id = event.target.dataset.removeItemId
    let el = document.getElementById(id)
    el.remove()
  }

}
