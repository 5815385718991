import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "options" ]

  toggle() {
    this.containerTarget.classList.toggle('hidden')
    let options = this.optionsTarget.children
    for (var i = 0; i < options.length; i++) {
      options[i].classList.toggle('hidden')
    }
  }

}
