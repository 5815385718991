// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
// Autosave, Dropdown, Tabs, Toggle, Popover, Slideover
import { Alert, Modal } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
// application.register('autosave', Autosave)
// application.register('dropdown', Dropdown)
application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('popover', Popover)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)

// import NestedForm from "stimulus-rails-nested-form"
// application.register("nested-form", NestedForm)

import ContentLoader from "stimulus-content-loader"
application.register("content-loader", ContentLoader)

import TextareaAutogrow from "stimulus-textarea-autogrow"
application.register("textarea-autogrow", TextareaAutogrow)

import CharacterCounter from "stimulus-character-counter"
application.register("character-counter", CharacterCounter)

import {delegate} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
delegate('html', {
  content(reference) {
    const title = reference.getAttribute('title');
    reference.removeAttribute('title');
    return title;
  },
  allowHTML: true,
  theme: 'gh',
  placement: 'top-start',
  arrow: false,
  delay: [50,150],
  target: '.tippy'
});
