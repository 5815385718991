import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".cursor-move",
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let itemId = event.item.dataset.itemId
    let scopeId = event.item.dataset.scopeId
    let data = new FormData()
    data.append("position", event.newIndex +1)

    Rails.ajax({
      url: this.data.get("url").replace(":item_id", itemId).replace(":scope_id", scopeId),
      type: 'PATCH',
      data: data
    })
  }
}
