import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log('fields found');
  }

  update() {
    let parts = event.target.dataset.updateParts
    let el = event.target
    let url = this.element.action
    let data = new FormData()
    let key = event.target.name
    let value
    if (event.target.type == 'checkbox')
      { value = event.target.checked ? true : false }
    else
      { value = event.target.value }

    data.append(key, value)

    Rails.ajax({
      url: url,
      type: 'PUT',
      data: data,
      success: () =>  { this._dispatch('success', el, parts) },
      error: () =>  { this._dispatch('error', el, parts) }
    })
  }

  _dispatch(result, element, updateParts) {
    if (result === 'success') {
      this._highlight(element, 'green')
      console.log('updated ' + element.name);
      if (updateParts) {
        let parts = JSON.parse(updateParts)
        parts.forEach(p => {
          this._replace.call(p)
        })
      }
    } else if (result === 'error') {
      this._highlight(element, 'red')
      console.log(event.target.response);
    }
  }

  _highlight(item, color) {
    item.classList.add(`bg-${color}-100`,`border-${color}-500`)
    item.classList.add('transition', 'duration-700', 'ease-out')
    setTimeout(function() {
      item.classList.remove(`bg-${color}-100`,`border-${color}-500`)
      setTimeout(function() {
        item.classList.remove('transition', 'duration-700', 'ease-out')
      }, 700);
    }, 1200);
  }

  _replace() {
    let node = document.getElementById(this.id)
    Rails.ajax({
      type: "GET",
      url: this.url,
      success: (_data, _status, xhr) => {
        node.outerHTML = xhr.response
      }
    })
  }


}
