import { Controller } from "@hotwired/stimulus"
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import DragDrop from '@uppy/drag-drop'
import ProgressBar from '@uppy/progress-bar'

export default class extends Controller {

  connect() {
    this._setupUppy(this.element)
  }

  _setupUppy(element) {
    let form = element.closest('form')
    let direct_upload_url = element.dataset.uppyDirectUploadPath
    let field_id = element.dataset.uppyFieldId

    let uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: false,
      // logger: Uppy.debugLogger,
      restrictions: {
        allowedFileTypes: ['image/*', 'video/*', 'audio/*'],
      }
    })

    uppy.use(XHRUpload, {
      endpoint: direct_upload_url,
    })

    uppy.use(DragDrop, {
      target: '.for-DragDrop',
      height: '180px',
      locale: {
        strings: {
          dropHereOr: 'Hier ablegen oder %{browse}',
          browse: 'auswählen'
        }
      }
    })

    uppy.use(ProgressBar, {
      target: '.for-ProgressBar',
      hideAfterFinish: false
    })

    uppy.on('upload-success', function (file, response) {
      var uploadedFileData = JSON.stringify(response.body)

      var hiddenField = document.getElementById(field_id)
      hiddenField.value = uploadedFileData

      var fileList = element.querySelector('.for-FileList')
      var fileListContainer = element.querySelector('.fileListContainer')
      fileList.innerText = JSON.parse(uploadedFileData)['metadata']['filename']
      fileListContainer.classList.remove('opacity-0')

      uppy.reset()
    })

  }

}
